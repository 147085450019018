.help-component {
  position: fixed; 
  top: 0; 
  right: -100%; 
  z-index: 999; 
  width: 100%;
  height: auto; 
  display: flex;
  justify-content: center;
  align-items: center;
  top:56px;
  transition: right 1s ease; 
}

.help-component.show {
  right: 0; 
}

.hidden {
  display: none; 
}
