.overlay-active-help {
  position: relative;
  width: 96%;
  height: 90vh;
  background-color: #fff;
  border: 1px solid #ddd;
  z-index: 9999;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.overlay-content-help {
  overflow-y: auto;
  top: 120px;
  max-height: calc(100vh - 120px);
  padding: 20px;
  flex-grow: 1;
}

.no-data {
  padding: 300px;
  display: flex;
  justify-content: center;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.help-text-custom {
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.help-accordion-content {
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.file-upload-status {
  position: absolute;
  display: block;
  font-size: small;
  top: 0px;
  padding: 5px 10px;
  border-radius: 2px;
  border: 1px solid green;
  margin: 10px;
  padding-top: 15px;
  text-align: center;
  left: 35%;
  color: green;
  display: flex;
}

.copy-to-clipboard {
  background: #33475b;
  padding: 4px 10px 5px 10px;
  color: #fff;
  cursor: pointer;
  border-radius: 2px;
  height: 23px;
  font-size: x-small;
  margin-left: 10px;
}

.file-upload-status-close {
  position: absolute;
  top: 0;
  right: 41%;
  cursor: pointer;
}

p > img, p > video {
  width: 100% !important;
}